exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-accounting-features-js": () => import("./../../../src/pages/accounting-features.js" /* webpackChunkName: "component---src-pages-accounting-features-js" */),
  "component---src-pages-accounting-js": () => import("./../../../src/pages/accounting.js" /* webpackChunkName: "component---src-pages-accounting-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-create-account-js": () => import("./../../../src/pages/create-account.js" /* webpackChunkName: "component---src-pages-create-account-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-partners-become-a-partner-js": () => import("./../../../src/pages/partners/become-a-partner.js" /* webpackChunkName: "component---src-pages-partners-become-a-partner-js" */),
  "component---src-pages-pricing-js": () => import("./../../../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-terms-of-use-js": () => import("./../../../src/pages/terms-of-use.js" /* webpackChunkName: "component---src-pages-terms-of-use-js" */),
  "component---src-pages-v-card-js": () => import("./../../../src/pages/v-card.js" /* webpackChunkName: "component---src-pages-v-card-js" */)
}

