import { createTheme } from "@mui/material/styles";
import typography from "./typography";
const theme = createTheme({
  palette: {
    primary: {
      main: "#1976d2",
    },
    secondary: {
      main: "#dc004e",
    },
    background: {
      default: "#f4f6f8",
    },
  },
  typography,
  layout: {
    contentWidth: 1140,
  },
});

export default theme;
