import React from "react"; // Ensure React is imported
import WrapRootElement from "./src/wrapRootElement";

import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

export const wrapRootElement = ({ element }) => {
  return <WrapRootElement element={element} />;
};
