import "@fontsource/noto-sans/300.css";
import "@fontsource/noto-sans/400.css";
import "@fontsource/noto-sans/500.css";
import "@fontsource/noto-sans/700.css";

const typography = {
  fontSize: 16,
  fontFamily: ["Noto Sans", "sans-serif"].join(","),
};

export default typography;
